.App {
  text-align: center;
}

@media screen and (min-width: 1400px) {
  .App {
    border-left: 3px solid black;
    border-right: 3px solid black;
  }
}
